import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './index.module.css';
import { Helmet } from "react-helmet";

function Home(props) {
  const [loaded, setLoaded] = useState(false);
  const [landingPageConfig, setLandingPageConfig] = useState(null);

  const isUUID = (uuid) => {
    let s = '' + uuid;

    s = s.match(
      '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
    );
    if (s === null) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!loaded && landingPageConfig) {
      const scriptTag = document.createElement('script');
      scriptTag.src = 'https://cdn.digma.io/dist/digma.min.2.0.0.js';
      scriptTag.addEventListener('load', () => setLoaded(true));
      document.body.appendChild(scriptTag);
    }
  }, [loaded, landingPageConfig]);

  useEffect(() => {
    if (props.projectId) {
      let obj = null;
      if (isUUID(props.projectId)) {
        obj = { id: props.projectId };
      } else {
        obj = { slug: props.projectId };
      }
      fetch('https://api.digma.io/api/player/landingpage', {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'access-control-allow-origin': '*',
        },
        body: JSON.stringify(obj),
      })
        .then((response) => response.json())
        .then((data) => {
          setLandingPageConfig(data);
        })
        .catch((err) => {
          alert('error');
        });
    }
  }, [props.projectId]);

  const configuration = useMemo(() => {
    if (
      landingPageConfig?.projectConfig?.landingPage &&
      landingPageConfig?.projectConfig?.landingPage?.enabled
    ) {
      return landingPageConfig?.projectConfig?.landingPage;
    } else {
      if (landingPageConfig?.accountConfig) {
        return landingPageConfig?.accountConfig;
      } else {
        return {
          headerColor: '#1d293f',
          primaryColor: 'rgba(154, 59, 59, 1)',
          secondaryColor: 'rgba(169, 26, 173, 1)',
          logo: 'https://digmacustomers.blob.core.windows.net/4fe433c9-fb0f-47d4-9f59-37f1909ec6b8/5d77e52f-71f6-4aba-9e99-4f4f5c8a2bbb.png',
          description: '',
          videoColor: 'rgba(229, 229, 229, 1)',
          bodyColor: 'rgba(255, 255, 255, 1)',
          title: '',
          subtitle: '',
          buttonText: '',
        };
      }
    }
  }, [landingPageConfig]);
  
  const getTitle = useCallback(() => {
    if (landingPageConfig && !landingPageConfig?.whiteLabelEnabled) {
      return `${configuration?.title} - Powered by Digma`;
    }
    return configuration?.title;
  }, [configuration])

  if (!configuration && !loaded && !landingPageConfig) {
    return null;
  }


  return (
    <>
      <Helmet>
        <title>{getTitle()}</title>
        <meta name="description" content={configuration?.subtitle} />
        <meta name="og:title" content={
          getTitle()
        } />
        <meta name="og:description" content={configuration?.subtitle} />
        <meta name="og:image" content={configuration?.logo} />
        <meta name="og:url" content={window.location.href} />
        <meta name="og:site_name" content={getTitle()} />
      </Helmet>
      <div className={styles.container}>
        <div
          className={styles.header}
          style={{
            backgroundColor: configuration?.headerColor || '#fff',
          }}
        >
          {configuration?.logo && (
            <img src={configuration?.logo} alt="logo" className={styles.logo} />
          )}
        </div>

        <div
          style={{
            backgroundColor: configuration?.backgroundImage
              ? 'transparent'
              : configuration?.bodyColor || '#fff',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',

            backgroundImage: configuration?.backgroundImage
              ? `url(${configuration?.backgroundImage})`
              : 'none',
            backgroundSize: 'cover',
          }}
        >
          <div
            className={styles.videoWrapper}
            style={{
              backgroundColor: configuration?.backgroundImage
                ? 'transparent'
                : configuration?.videoColor ||
                configuration?.headerColor ||
                '#fff',
            }}
          >
            <div className={styles.video}>
              <div
                style={{ boxShadow: '0px 0px 17px 1px rgb(97 97 97 / 41%)' }}
                id="digma-embed"
                className="digma"
                data-projectId={landingPageConfig?.projectId}
              ></div>
            </div>
          </div>

          <div
            className={styles.body}
            style={{
              backgroundColor:
                (!configuration?.backgroundImage && configuration?.bodyColor) ||
                'inherit',
            }}
          >
            {configuration?.title && (
              <h1
                className={styles.title}
                style={{
                  color: configuration?.titleColor || '#333',
                }}
              >
                {configuration?.title}
              </h1>
            )}
            {configuration?.subtitle && (
              <h2
                className={styles.subtitle}
                style={{
                  color: configuration?.subtitleColor || '#333',
                }}
              >
                {configuration?.subtitle}
              </h2>
            )}
            {configuration?.description && (
              <>
                <div
                  style={{
                    color: configuration?.textColor || '#333',
                  }}
                  className={styles.innerbody}
                  dangerouslySetInnerHTML={{
                    __html: configuration?.description,
                  }}
                ></div>
              </>
            )}

            {configuration?.buttonText && (
              <>
                <div
                  onClick={() => {
                    window.open(
                      configuration?.buttonUrl,
                      configuration?.buttonTarget
                    );
                  }}
                  className={styles.button}
                  style={{
                    backgroundColor: configuration?.buttonColor || '#333',
                    color: configuration?.buttonTextColor || '#fff',
                  }}
                >
                  {configuration?.buttonText}
                </div>
              </>
            )}
          </div>
        </div>

        {landingPageConfig && !landingPageConfig?.whiteLabelEnabled && (
          <div className={styles.footer}>Powered by Digma</div>
        )}
      </div></>
  );
}

export default Home;
