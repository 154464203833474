import { Router } from '@reach/router';
import React, { Suspense } from 'react';
import './App.css';
import Home from './pages/home';

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router style={{ height: '100%' }}>
        <Home path="/:projectId" />
      </Router>
    </Suspense>
  );
}

export default App;
